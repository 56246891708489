import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-language',
	templateUrl: './language.page.html',
	styleUrls: ['./language.page.scss']
})
export class LanguagePage {
	@Input() language: string = 'pt';

	constructor(private modalController: ModalController) {}

	changeLang(lang: any) {
		this.modalController.dismiss(lang.target.value);
	}
}
