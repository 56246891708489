import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import CryptoJS from 'crypto-js';
import { EventsService } from './events.service';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	public auth_key: string = '';
	public key: string = `aGVlZWV5IQ==`;
	public language: string = 'pt';

	constructor(private http: HttpClient, private eventsService: EventsService) {
		this.eventsService.subscribe('update:language', async (language) => {
			this.language = language;
		});
	}

	setAuth(auth: string) {
		this.auth_key = auth;
	}

	get(url: string, data?: any) {
		let authorization = this.generateToken(new Date().getTime() / 1000, this.genRandomString(8));
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'X-Requested-With': environment.identifier,
			'360city-Authorization': `app:${authorization}`,
			'Accept-Language': this.language
		});

		if (this.auth_key) {
			headers = headers.set('Authorization', this.auth_key);
		}

		let params = new HttpParams();

		if (data) {
			for (let p in data) {
				if (data[p]) {
					params = params.set(p, data[p]);
				}
			}
		}

		return this.http.get(environment.baseUrl + url, { headers: headers, params: params });
	}

	post(url: string, data: any) {
		let authorization = this.generateToken(new Date().getTime() / 1000, this.genRandomString(8));
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'X-Requested-With': environment.identifier,
			'360city-Authorization': `app:${authorization}`,
			'Accept-Language': this.language
		});

		if (this.auth_key) {
			headers = headers.set('Authorization', this.auth_key);
		}

		return this.http.post(environment.baseUrl + url, data, { headers: headers });
	}

	generateToken(timestamp, randomString) {
		let auth_data_raw = timestamp + randomString;
		let auth_key_raw = this.decrypt(this.key);

		let hash = CryptoJS.HmacSHA256(auth_data_raw, auth_key_raw);
		let hashString = CryptoJS.enc.Base64.stringify(hash);
		let key = this.encrypt([timestamp, randomString, hashString].join(';'));

		return key;
	}

	encrypt(myString) {
		const encodedWord = CryptoJS.enc.Utf8.parse(myString);
		const encoded = CryptoJS.enc.Base64.stringify(encodedWord);
		return encoded;
	}

	decrypt(str) {
		// Going backwards: from bytestream, to percent-encoding, to original string.
		return decodeURIComponent(
			atob(str)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);
	}

	genRandomString(length) {
		var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
		var charLength = chars.length;
		var result = '';
		for (var i = 0; i < length; i++) {
			result += chars.charAt(Math.floor(Math.random() * charLength));
		}
		return result;
	}
}
